<template>
  <div class='desc'>
    <Title :chs="chs" :en="en" :flag="$route.path" :desc="desc" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      chs: '公司简介',
      en: 'Company Introduction',
      desc: ''
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$axios.get(this.$api.selectEnterpriseInfoDetail).then((res) => {
        this.desc = res.result.introduction
      }).catch((err) => {
        this.$message.error(err.desc)
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.desc {
  height: 100%;
  background-color: #EEF4FF;
}
</style>
